.notFound__container {
	padding-bottom: 100px;
	padding-top: 200px;
	min-height: 60vh;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	background-color: #cedcd6;
}

.notFound__container h1 {
	font-size: 2.5rem;
	padding: 1.5rem 2rem;
	font-weight: 800;
}

.notFound__goBackBtn {
	padding: 0.8rem 1.5rem;
	border: none;
	background-color: #477053;
	color: #fff;
	letter-spacing: 1px;
	font-weight: 600;
	margin-bottom: 1.5rem;
	cursor: pointer;
}

@media only screen and (min-width: 768px) {
	.notFound__container h1 {
		font-size: 4rem;
		padding: 2rem 2rem;
	}
}
