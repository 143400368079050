.bg-dark-green {
	background-color: #507d5c;
}
.belief__section {
	width: 100%;
	max-width: 1600px;
	margin: 0 auto;
	padding: 0;
	color: #fff;
}

.belief__section h3 {
	font-size: 1.4rem;
	margin-bottom: 3.5rem;
	text-align: center;
}

.feature__list {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 2rem;
}

.feature__item {
	max-width: 400px;
	padding: 2rem;
	background: rgb(68, 113, 73);
	border-radius: 5px;
	display: flex;
	flex-flow: row nowrap;
	gap: 2rem;
}
.feature__item svg {
	font-size: 3rem;
}
.feature__item h4 {
	font-size: 1rem;
	line-height: 1.2rem;
	margin-bottom: 10px;
}

.feature__item p {
	font-size: 1rem;
	line-height: 1.3rem;
	text-align: left;
}

@media only screen and (min-width: 500px) {
	.belief__section h3 {
		font-size: 1.6rem;
		margin-bottom: 4.5rem;
	}
}

@media only screen and (min-width: 768px) {
	.belief__section h3 {
		font-size: 2.2rem;
	}
}

@media only screen and (min-width: 1352px) {
	.feature__item {
		max-width: 620px;
	}
}
