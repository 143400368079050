.slick-prev,
.slick-next {
	height: 100%;
	padding: 0 0.5rem;
	z-index: 20;
}

.slick-prev {
	left: 25px;
}

.slick-next {
	right: 25px;
}

.slick-prev:before,
.slick-next:before {
	font-size: 30px;
	opacity: 0;
}

.slick-prev:before {
	content: '←';
}

.slick-next:before {
	content: '→';
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
	opacity: 1;
}

/*.slick-prev:hover,
.slick-next:hover {
	background: rgba(255, 255, 255, 0.2);
}*/
.slick-dots {
	bottom: 40px;
}

.slick-dots li button:before {
	font-size: 10px;
}

.slick-list,
.slick-track,
.slick-slide div {
	height: 100%;
}

.header__slick .slick-slide img {
	width: 100%;
	min-height: 100%;
	max-height: 100%;
	object-fit: cover;
	object-position: center;
}

.header__slick .slick-slide > div > div {
	display: flex;
	justify-content: center;
}

.header__slick .slick-slide > div > div > .slick__group-text {
	position: absolute;
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	justify-content: center;
	align-items: center;
	z-index: 10;
}

.header__slick div.slick__text {
	color: #fff;
	height: unset;
	padding-top: 3em;
	width: 100%;
	text-align: center;
}
.header__slick .slick__text h4 {
	font-size: 1em;
	letter-spacing: -1px;
}
.header__slick .slick__text h2 {
	font-size: 2.2em;
	line-height: 2.5rem;
	letter-spacing: -2px;
}

.header__slick .slick__text button {
	margin-top: 1.5rem;
}

@media only screen and (min-width: 768px) {
	.header__slick .slick-prev,
	.header__slick .slick-next {
		padding: 0 2rem;
	}

	.header__slick .slick-dots li button:before {
		font-size: 15px;
	}

	.header__slick div.slick__text {
		text-align: left;
	}

	.header__slick .slick__text h4 {
		font-size: 1.2em;
		letter-spacing: -1px;
	}
	.header__slick .slick__text h2 {
		font-size: 4em;
		line-height: 4.2rem;
		letter-spacing: -2px;
	}

	.header__slick .slick__text button {
		margin-top: 2rem;
	}
}
