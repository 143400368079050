@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,700;1,800&display=swap');
:root {
	--font-family-primary: 'Open Sans', sans-serif;
	--font-family-secondary: 'Lato', sans-serif;
	--color-bg: #fff; /*#ecf5ff;*/
	--color-text: #1d1d1d;
	--color-primary: rgb(71, 112, 83);
	--color-primary-hover: rgb(59, 95, 70);
}

/** REACT GALLERY CAROUSEL STYLE MODS */
div._3q7r8 {
	background-color: #505050;
}
figcaption._2ljUm {
	width: 100%;
	padding: 3px 8px;
	background: rgba(255, 255, 255, 0.8);
}

figcaption._2ljUm span.__JnHV {
	background: unset;
	opacity: unset;
	width: 100%;
	font-size: 0.7rem;
	text-align: right;
	line-height: 0.8rem;
}

@media only screen and (min-width: 768px) {
	figcaption._2ljUm span.__JnHV {
		font-size: 0.8rem;
		line-height: 0.9rem;
	}
}

/** embedded video */
.player div.Title_module_title__f9050681 {
	display: none !important;
}
