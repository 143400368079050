.pageHeaderSubtext {
	width: 100%;
	max-width: 1200px;
	padding: 2rem 1rem;
}

.pageHeaderSubtext h3 {
	font-size: 1.2rem;
	letter-spacing: 0.04rem;
	font-weight: 600;
	line-height: 1.8rem;
	margin: 0 auto;
	text-align: center;
}

@media only screen and (min-width: 768px) {
	.pageHeaderSubtext h3 {
		font-size: 1.6rem;
		letter-spacing: 0.05rem;
		line-height: 2.5rem;
		text-align: center;
		max-width: 800px;
	}
}

.mapouter {
	position: relative;
	text-align: right;
	height: 305px;
	width: 100%;
	margin-top: 2rem;
}
.gmap_canvas {
	overflow: hidden;
	background: none !important;
	height: 305px;
	width: 100%;
}
