.go-to-top-btn {
	border-radius: 50%;
	background: #333;
	border-radius: 50%;
	bottom: 25px;
	box-shadow: 0 0.125rem 0.625rem 0rgba (0, 0, 0, 0.1);
	color: #fff;
	cursor: pointer;
	opacity: 1;
	padding: 6px 8px;
	position: fixed;
	right: 90px;
	transition: background 0.5s;
	opacity: 1;
	box-shadow: 0 0.125rem 0.625rem 0 rgb(0 0 0 / 10%);
}

.go-to-top-btn:hover {
	background: rgb(41, 41, 41);
}

.go-to-top-btn svg {
	height: 14px;
	width: 14px;
}
