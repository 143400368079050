.section__container {
	display: flex;
	flex-flow: column nowrap;
}
.section__content {
	display: flex;
	flex-flow: column nowrap;
	gap: 2rem;
}

.section__content img {
	width: 100%;
	max-height: 350px;
	object-fit: cover;
	flex: 1;
	order: 2;
	margin: unset auto;
}

.section__content video {
	width: 100%;
}

.section__content .section__contentText {
	order: 1;
}

.section__contentText {
	padding: 0;

	flex: 2;
}

ol {
	font-size: 1rem;
	max-width: 700px;
}

ol li > ul {
	padding-left: 2rem;
}

.section__content iframe {
	margin: 0 auto;
	width: 100%;
	height: 300px;
}

@media only screen and (min-width: 630px) {
	.section__content iframe {
		height: 315px;
		width: 560px;
	}
}

@media only screen and (min-width: 800px) {
	.section__content {
		max-width: 800px;
	}
}

@media only screen and (min-width: 1200px) {
	.section__content iframe {
		height: 454px;
		width: 100%;
	}
	.section__content {
		max-width: 1000px;
		width: 100%;
		/* display: flex;
		flex-flow: row nowrap; */
		justify-content: space-between;
		align-items: start;
		align-content: flex-start;
	}

	.section__contentText {
		padding: 0 1.5rem;
	}
	.admission__section .section__container img {
		height: 100%;
		width: 500px;
		object-fit: cover;
		max-height: 100%;
	}

	.section__content video {
		max-width: 560px;
	}

	.section__content img,
	.section__content .section__contentText {
		order: unset;
	}
}
@media only screen and (min-width: 1400px) {
	.section__content iframe {
		height: 315px;
		width: 560px;
	}
	.section__content {
		max-width: 100%;
		display: flex;
		flex-flow: row nowrap;
	}

	.admission__section .section__content img {
		width: 600px;
	}
}
