.home {
	font-family: var(--font-family-primary);
}

.section__titleContent {
	font-size: 1.5rem;
	text-align: center;
	max-width: 650px;
	margin: 0 auto;
	margin-bottom: 2.8rem;
	color: #3e6740;
	line-height: 2.2rem;
	font-weight: 800;
}

.section__titleContent > span {
	font-size: 1.2rem;
	line-height: 2rem;
	color: #444;
	font-weight: 400;
}

.welcome__section {
	display: flex;
	flex-flow: column nowrap;
	gap: 30px;
}

.section__about > div {
	height: 100%;
	width: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 1440px;
	position: relative;
}
.welcomeTextContent {
	max-width: 800px;
	margin-right: 0px;
	margin-bottom: 2.5rem;
}

.welcomeImageContent > * {
	max-width: 100%;
}

.welcomeTextContent > p {
	text-align: justify;
}

.section__about {
	height: 600px;
	width: 100%;
	position: relative;
	background-color: #fff;
	padding: 0;
	white-space: auto;
}

.section__about p {
	text-align: justify;
}

.section_textContentTitle {
	margin-bottom: 1.7rem;
	line-height: 2.5rem;
	font-weight: 800;
	font-size: 1.8rem;
	text-align: center;
}
.about__bg {
	width: 100%;
	background-color: rgba(116, 146, 153, 0.94);
	height: 100%;
	color: #fff;
	padding: 2.5rem;
	margin: auto;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
}

.section__textContentButton {
	padding: 15px 25px;
	margin: 30px 0 0 0;
	background-color: #41694e;
	border: 0;
	max-width: 141.42px;
}

.section__programs img {
	max-width: 100%;
}

.section__programs {
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
	gap: 30px;
}

.programTextContent > p {
	text-align: justify;
}

.programTextContent {
	max-width: 800px;
	margin-left: 0px;
	margin-bottom: 2.5rem;
}

.programTextContent > img {
	max-width: 100%;
}

.programTextContent .section__textContentButton {
	margin-bottom: 0px;
}

.contact__formContainer > div {
	width: 100%;
}

.contact__formContainer .row {
	flex-flow: column nowrap;
	justify-content: space-around;
	gap: 4rem;
}
.contact__formContainer .row > .col {
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}
.contact__form {
	width: 100%;
}
.contact__form h3 {
	font-size: 1.6rem;
	margin-bottom: 2.5rem;
	font-weight: 800;
}

.contact__form .form__group {
	display: flex;
	flex-flow: column nowrap;
	gap: 1.1rem;
}

.form__group input,
.form__group textarea {
	padding: 0px 10px;
	font-size: 1rem;
	width: 100%;
	border: 1px solid #95a396;
}

.form__group textarea {
	padding: 15px;
}

.form__group input {
	height: 50px;
}

.contact__infoGroup {
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: flex-start;
	gap: 1.8rem;
}

.contact__infoGroupItem {
	display: flex;
	flex-flow: row nowrap;
	max-width: 380px;
	line-height: 1.5rem;
	gap: 1rem;
}
.contact__infoGroupItemTextTitle {
	color: #506f54;
	font-weight: 700;
	font-size: 1.4rem;
	margin-bottom: 0.7rem;
}

.contact__infoGroupItemIcon {
	font-size: 3rem;
}

.section__aboutUs {
	height: 540px;
}

.section__aboutUs > * {
	position: relative;
	height: 100%;
	width: 100%;
	margin: 0 auto;
}

.section__aboutUs .about__gradient {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
	background-color: rgb(221 229 228 / 94%);

	/* linear-gradient(90deg, rgb(175 186 189 / 1%) 5%, rgb(242 246 245) 43%); */
	/* background-color: #d7e2e1; */
}

.section__aboutUs .about__textContainer {
	margin: auto;

	text-align: justify;
	z-index: 5;
	position: absolute;
	top: 0;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-content: center;
	height: 100%;
	width: 100%;
	padding: 2rem;
}

.section__aboutUs .about__textContainer > * {
	max-width: 550px;
	margin: 0 auto;
}

.section__aboutUs img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

@media only screen and (min-width: 630px) {
	.section__titleContent {
		font-size: 1.8rem;
		line-height: 2.5rem;
	}
	.section__titleContent > span {
		font-size: 1.5rem;
	}
}

@media only screen and (min-width: 768px) {
	.section__aboutUs {
		height: 600px;
		position: relative;
	}

	.section__about {
		height: 600px;
	}

	.about__bg {
		max-width: 600px;
		height: unset;
	}

	.contact__formContainer .row {
		flex-flow: row wrap;
		align-items: start;
		justify-content: space-between;
	}

	.contact__formContainer .col:nth-child(1) {
		flex: 2;
	}

	.contact__formContainer .col:nth-child(2) {
		flex: 1;
	}

	.contact__form {
		max-width: 600px;
	}

	.section_textContentTitle {
		font-size: 2.2rem;
		text-align: left;
	}
}

@media only screen and (min-width: 1080px) {
	.section__programs {
		display: flex;
		flex-flow: column nowrap;
	}

	.welcomeImageContent > *,
	.programImageContent > * {
		max-width: 800px;
	}

	.section__aboutUs img {
		height: 100%;
		z-index: 1;
		max-width: 600px;
		object-fit: cover;
		object-position: revert;
	}

	.section__aboutUs .about__gradient {
		background: linear-gradient(90deg, rgb(175 186 189 / 1%) 5%, rgb(242 246 245) 43%);
	}

	.section__aboutUs .about__textContainer {
		position: absolute;
		left: 50%;
		max-width: 500px;
		text-align: justify;
	}
}

@media only screen and (min-width: 1200px) {
	.section__aboutUs {
		height: 600px;
	}

	.section__aboutUs .about__gradient {
		/* background: linear-gradient(90deg, rgb(175 186 189 / 1%) 5%, rgb(242 246 245) 43%); */
		background: linear-gradient(90deg, rgb(175 186 189/30%) 5%, #f2f6f5 46%);
	}

	.section__aboutUs .about__textContainer {
		position: absolute;
		left: 55%;
		max-width: 500px;
		text-align: justify;
	}

	.section__aboutUs img {
		max-width: 900px;
		height: 100%;
	}

	.welcome__section {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		align-content: center;
	}
	.welcomeTextContent {
		max-width: 560px;
		margin-right: 0;
		margin-bottom: 0;
	}

	.welcomeImageContent > * {
		max-width: 600px;
	}

	.section__titleContent {
		max-width: 900px;
	}

	.programTextContent {
		max-width: 560px;
		margin-right: 0;
		margin-bottom: 0;
	}

	.programImageContent > img {
		max-width: 600px;
	}

	.section__programs {
		display: flex;
		flex-flow: row nowrap;
	}

	.about__bg {
		width: 450px;
		height: 80%;
		background-color: rgba(116, 146, 153, 0.94);
		position: absolute;
		color: #fff;
		padding: 2.5rem;
		margin: 0;
		bottom: 0;
		right: 10%;
	}
}

@media only screen and (min-width: 1400px) {
	.section__aboutUs .about__textContainer {
		max-width: 600px;
	}
}

@media only screen and (min-width: 1920px) {
	.section__aboutUs .about__gradient {
		background: linear-gradient(90deg, rgb(175 186 189/30%) 5%, #f2f6f5 35%);
	}
}

/*.welcome__section {
	background-image: url('../../assets/images/welcome-bg.jpg');
	background-color: #cccccc;
	height: 600px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}*/
