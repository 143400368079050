.header {
	height: calc(60vh);
	min-height: 400px;
	background-color: rgb(110, 136, 106);
	display: flex;
	justify-content: center;
}

.header__slick.slick-slider {
	width: 100%;
	/* ff 3.6+ */
	background: -moz-radial-gradient(circle at 0% 0%, rgba(68, 85, 70, 1) 0%, rgba(54, 61, 52, 1) 28%, rgba(15, 24, 21, 1) 100%);

	/* safari 5.1+,chrome 10+ */
	background: -webkit-radial-gradient(circle at 0% 0%, rgba(68, 85, 70, 1) 0%, rgba(54, 61, 52, 1) 28%, rgba(15, 24, 21, 1) 100%);

	/* opera 11.10+ */
	background: -o-radial-gradient(circle at 0% 0%, rgba(68, 85, 70, 1) 0%, rgba(54, 61, 52, 1) 28%, rgba(15, 24, 21, 1) 100%);

	/* ie 10+ */
	background: -ms-radial-gradient(circle at 0% 0%, rgba(68, 85, 70, 1) 0%, rgba(54, 61, 52, 1) 28%, rgba(15, 24, 21, 1) 100%);

	/* global 92%+ browsers support */
	background: radial-gradient(circle at 0% 0%, rgba(68, 85, 70, 1) 0%, rgba(54, 61, 52, 1) 28%, rgba(15, 24, 21, 1) 100%);
}

.slick__image {
	position: relative;
	margin-bottom: 25px;
	border-radius: 2px;
	overflow: hidden;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.15);
}
.slick__image:after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0.55;
	background-image: linear-gradient(45deg, darken(#13abdb, 5%), #b10dc9);
}

.slick__text-group span {
	font-size: 0.9rem;
	line-height: 1.8rem;
	color: #ffffff;
	font-style: italic;
}

@media only screen and (min-width: 500px) {
	.slick__text-group span {
		font-size: 1rem;
	}
}

@media only screen and (min-width: 768px) {
	.header {
		height: 800px;
	}

	.slick__text-group span {
		font-size: 1.6rem;
		line-height: 3rem;
		font-style: italic;
	}
}
