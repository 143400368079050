.programs__section .section__content {
	align-items: center;
	align-content: center;
}

.programs__section p {
	text-align: justify;
}

.programs__section .section__content ul {
	margin-top: 30px;
	margin-left: 20px;
}

div.section__content.alignTop {
	align-items: start;
}

.section__content > h3 {
	flex: 1;
	font-size: 2.5rem;
	margin-bottom: 1rem;
	color: #c8c8c8;
}

.section__content .section__contentText {
	flex: 1.5;
}
.section__contentText h3 {
	font-size: 1.6rem;
	margin-bottom: 2.2rem;
	display: flex;
	flex-flow: column nowrap;
	gap: 1rem;
	text-align: center;
	font-weight: 800;
	font-family: var(--font-family-primary);
}

.section__contentText h3 > span {
	font-size: 0.8rem;
	color: #e2aa6c;
	letter-spacing: 0.15rem;
}

.section__contentText > p {
	margin-bottom: 1rem;
}

.slick-slider.programs__slider {
	margin: 0 auto;
	max-width: 1300px;
}

.programs__slider .slick-next:before,
.programs__slider .slick-prev:before {
	opacity: 0.5;
	color: #c7c7c7;
	font-size: 40px;
	position: absolute;
}
.programs__slider .slick-next,
.programs__slider .slick-prev {
	display: none;
}

.programs__slider .slick-next:before {
	right: 0;
	display: none;
}

.programs__slider .slick-prev:before {
	left: 0;
	display: none;
}

.programs__slider .slick-next:hover:before,
.programs__slider .slick-prev:hover:before {
	opacity: 1;
	color: #444;
}

.programs__slider .section__content svg {
	font-size: 8rem;
}

@media only screen and (min-width: 768px) {
	.programs__section .section__content {
		padding: 0 6rem;
	}
	.programs__slider .section__container {
		/* margin: 0 3rem; */
	}

	.section__contentText h3 {
		text-align: left;
		font-size: 2.3rem;
		line-height: 2.5rem;
	}

	.section__contentText h3 > span {
		font-size: 1rem;
	}
}

@media only screen and (min-width: 968px) {
	.programs__slider .slick-next:before,
	.programs__slider .slick-prev:before {
		display: block;
	}
}

@media only screen and (min-width: 1200px) {
	.programs__slider .section__container {
		/* margin: 0 4rem; */
	}
}
