.navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: fixed;
	top: 0;
	color: #fff;
	font-size: 0.9rem;
	transition: background-color 0.8s;
	font-weight: 600;
	text-transform: capitalize;
	letter-spacing: 0.8px;
	z-index: 100;
}

.navmenubtn {
	z-index: 10000;
}

.navbar__logo > a {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 2px;
}

.navbar__logo > a > .navbar__logo-text {
	font-size: 14px;
	font-weight: 700;
	line-height: 1.1;
	letter-spacing: 0;
}

.is__sticky {
	background-color: #fff;
	color: #444;
	box-shadow: 0 0.125rem 0.625rem 0 rgb(0 0 0 / 10%);
}

.navbar__container {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	flex: 1;
	max-width: 1440px;
}

.navbar__logo img {
	height: 45px;
}

.navbar__links {
	display: none;
}

.navbar__menu:hover {
	cursor: pointer;
	color: var(--color-primary-hover);
}

.navbar__links a,
.navbar__menu-container a {
	transition: color 0.5s;
}

.navbar__links a:hover,
.navbar__menu-container a:hover {
	color: var(--color-primary);
}

.navbar__menu-container {
	background-color: #fff;
	position: absolute;
	right: 1rem;
	color: #444;
	padding: 3rem;
}

.navbar__menu-links {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
	gap: 0.8rem;
}

@media only screen and (min-width: 768px) {
	.navbar__logo > a > .navbar__logo-text {
		font-size: 18px;
	}
}

@media only screen and (min-width: 1120px) {
	.navbar__container {
		padding: 1rem 2rem;
	}

	.navbar__logo img {
		height: 60px;
	}
	.navbar__links {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-end;
		align-items: center;
		gap: 25px;
	}

	.navbar__menu {
		display: none;
	}
}
