.article__container {
	min-height: 100vh;
	display: flex;
	justify-content: center;
}

.article__container article {
	padding: 1rem 2rem;
	margin-top: 100px;
	max-width: 1000px;
	width: 100%;
}

.article__container header h1 {
	font-size: 1.4rem;
	font-weight: 700;
	margin-bottom: 12px;
}

.article__container .article__subheader {
	display: flex;
	flex-flow: column nowrap;
	gap: 1px;
	font-size: 0.8rem;
	margin-bottom: 1.5rem;
}

.article__mainImageContainer {
	width: 100%;
}

.article__mainImageContainer img {
	width: 100%;
	max-width: 800px;
	max-height: 400px;
	object-fit: cover;
	margin-bottom: 1.5rem;
}

.article__body {
	margin: 1.5rem 0;
}

.article__body p {
	margin-bottom: 1.5rem;
}

.article__body ul {
	margin: 1.5rem 2rem;
}

.article__body ul li {
	margin: 0;
	line-height: 1.5rem;
}

.article__gallery {
	margin-bottom: 10rem;
}

.article__carousel {
	width: 100%;
	margin-top: 5rem;

	height: '500px';
	max-width: 800px;
}

.article__notFoundContainer {
	min-height: 80vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.article__loadingContainer {
	height: 100vh;
	min-height: 600px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.article__goBackBtn {
	border: none;
	background-color: none;
	background: none;
	cursor: pointer;
	font-size: 1rem;
	margin-bottom: 1.5rem;
}

.article__body h1,
.article__body h2,
.article__body h3,
.article__body h4,
.article__body h5,
.article__body h6 {
	margin-bottom: 0.8rem;
}

.noPublishedDate {
	font-style: italic;
	color: #b4b4b4;
	font-size: 12px;
}

@media only screen and (min-width: 768px) {
	.article__container header h1 {
		font-size: 2rem;
		font-weight: 800;
		margin-bottom: 12px;
	}

	.article__container .article__subheader {
		font-size: 1rem;
	}

	.article__container article {
		padding: 2rem 3rem;
		margin-top: 120px;
	}

	.article__goBackBtn {
		margin-bottom: 2rem;
	}
}
