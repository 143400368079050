.section__pagination {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	padding: 0;
}

.section__paginationButtons {
	display: flex;
	flex-flow: row nowrap;
	gap: 2px;
	margin-bottom: 1rem;
}

.section__paginationButtons span {
	padding: 2px 4px;
	border: 1px solid #444;
	cursor: pointer;
	display: flex;
	align-items: center;
	color: #fff;
	background-color: #444;
}

.section__paginationButtons span.disabled {
	background-color: #ddd;
	cursor: not-allowed;
	border: 1px solid #ddd;
	color: rgb(182, 182, 182);
}

.section__paginationButtons span svg {
	font-size: 20px;
}

.noNewsFound__container {
	width: 100%;
	min-height: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.noNewsFound__container h2 {
	font-weight: 700;
	color: #cbcbcb;
}

.card__container {
	position: relative;
}

.card__loading {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media only screen and (min-width: 968px) {
	.section__pagination {
		padding: 0 3.5rem;
	}
}
