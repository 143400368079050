.page__header {
	background: rgb(141, 52, 52);
	height: 300px;
	color: #fff;
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}

.page__header-text {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}

.page__header-text h2 {
	font-size: 2rem;
	margin-left: 0;
}

.page__header > * {
	flex: 1;
}

.page__header__container {
	height: 100%;
	width: 100%;
}

.page__header > .page__header-image {
	height: 100%;
}

.page__header-image > img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	z-index: 1;
	position: absolute;
	object-position: top;
}

.page__header__container {
	position: absolute;
	z-index: 3;
}

@media only screen and (min-width: 1080px) {
	.page__header {
		height: 550px;
	}
	.page__header-text {
		justify-content: flex-start;
		width: 100%;
	}
	.page__header-text h2 {
		margin-left: 2rem;
		font-size: 2.5rem;
	}
}
