.button__recaptcha__container {
	display: flex;
	gap: 1rem;
	flex-flow: column nowrap;
	justify-content: space-between;
	align-items: center;
}

.button__recaptcha__container > * {
	margin: unset;
}

.button__recaptcha__container button {
	order: 2;
	max-width: unset;
	width: 100%;
}

@media only screen and (min-width: 600px) {
	.button__recaptcha__container {
		flex-flow: column nowrap;
	}

	.button__recaptcha__container button {
		max-width: 100%;
	}
}

@media only screen and (min-width: 600px) {
	.button__recaptcha__container {
		flex-flow: row nowrap;
	}

	.button__recaptcha__container button {
		max-width: 150px;
	}
}

@media only screen and (min-width: 768px) {
	.button__recaptcha__container {
		flex-flow: column wrap;
	}

	.button__recaptcha__container button {
		max-width: 100%;
	}
}

@media only screen and (min-width: 900px) {
	.button__recaptcha__container {
		flex-flow: row wrap;
	}

	.button__recaptcha__container button {
		max-width: 140px;
	}
}
