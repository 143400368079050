.footer {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #262729;
	color: #d9d9d9;
	font-weight: 500;
}

.footer__container {
	display: flex;
	flex: 1;
	padding: 2rem;
	flex-flow: column nowrap;
}

.footer__row {
	display: flex;
	flex-flow: column nowrap;
	gap: 1rem;
	font-size: 0.8rem;
}

.footer__col {
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer__row > * {
	flex: 1;
	padding: 1rem 0;
}

.footer__col-sm {
	flex: 0.5;
}

.footer__contact {
	text-align: center;
	letter-spacing: 2px;
}

.footer__contact h4 {
	font-size: 0.9rem;
	margin-bottom: 1rem;
	color: var(--color-primary);
}

.footer__contact .footer__contact-number {
	font-size: 1.5rem;
	margin-bottom: 1rem;
	font-weight: 500;
}

.footer__contact .footer__contact-address {
	font-size: 0.85rem;
	margin-bottom: 1rem;
	line-height: 1.4rem;
}

ul.footer__links {
	list-style: none;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 0.8rem;
	font-weight: 800;
	text-transform: capitalize;
	line-height: 0.6rem;
	letter-spacing: 1px;
}

ul.footer__links li a:hover {
	color: var(--color-primary-hover);
}

.footer__about {
	text-align: center;
	max-width: 650px;
	line-height: 1.8em;
}

.footer__socials {
	list-style: none;
	display: flex;
	flex-flow: row wrap;
	gap: 1rem;
}

.footer__socials > li > a {
	background-color: #d9d9d9;
	padding: 0.8rem;
	border-radius: 50%;
	display: flex;
	transition: all 0.4s;
}

.footer__socials > li > a:hover {
	background-color: var(--color-primary-hover);
}

.footer__socials svg {
	color: #1d1d1d;
	font-size: 20px;
}

.footer__copyright {
	color: #6c6c6c;
}

@media only screen and (min-width: 900px) {
	.footer__row {
		flex-flow: row nowrap;
	}

	.footer__contact {
		text-align: left;
	}

	ul.footer__links {
		flex-flow: column nowrap;
		justify-content: flex-end;
		align-items: flex-end;
	}

	.footer__col {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.footer__col-sm {
		display: flex;
		justify-content: flex-end;
	}

	.footer__about {
		text-align: left;
	}
}
