* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	scroll-behavior: smooth;
}

a {
	text-decoration: none;
	color: unset;
}

button {
	outline: none;
}

body {
	font-family: var(--font-family-secondary);
	color: #444;
}

main {
	min-height: 100vh;
}

.btn {
	padding: 1.25rem 2rem;
	border: none;
	font-family: var(--font-family-secondary);
	background-color: var(--color-primary);
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;
	transition: background-color 0.5s;
	letter-spacing: 1px;
}

p {
	font-size: 1rem;
	letter-spacing: 0.5px;
	line-height: 1.6em;
	font-family: var(--font-family-secondary);
}

h2 {
	font-size: 2rem;
	font-family: var(--font-family-primary);
}

.btn:hover {
	background-color: var(--color-primary-hover);
}
.container {
	padding: 0 1rem;
	max-width: 1440px;
}

.padding-y {
	padding: 3rem 0;
}

.section {
	padding: 3rem 0;
	display: flex;
	justify-content: center;
}

.section__container {
	max-width: 1440px;
	padding: 2rem;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 0.5rem;
}

.section__header {
	margin-bottom: 30px;
}

.section__header > p {
	font-size: 1.2rem;
	max-width: 300px;
	margin: 40px auto 2rem auto;
}

.section__header-bar {
	background: #477053;
	height: 7px;
	margin: 10px auto 50px auto;
	width: 70px;
}

.section__header-bar.bar__left {
	margin: 10px 0 50px 0;
}

.section__title {
	line-height: 2.5rem;
	font-weight: 800;

	color: #444;
}
input,
textarea {
	font-size: 2rem;
}

.section__container > * {
	flex: 1;
	width: 100%;
}

.bg-gray {
	background-color: rgb(243, 243, 243);
}

.bg-green {
	background-color: #d7e2e1;
}

.bg-blue {
	background-color: #2e3639;
	color: rgb(228, 228, 228);
}

.quote {
	display: flex;
	flex-flow: column nowrap;
	text-align: center;
}

.quote__text {
	font-size: 1.6em;
}

.quote__subtext {
	font-size: 1.1em;
}

.section__image {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	height: 100%;
	flex: 0.8;
}

.section__image > * {
	flex: 1;
}

.img-responsive {
	width: 100%;
}

.text__center {
	text-align: center;
}

.title__overlay {
	display: inline;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#fdcedb), to(#fdcedb));
	background-image: -o-linear-gradient(#fdcedb, #fdcedb);
	background-image: linear-gradient(#fdcedb, #fdcedb);
	background-position: 0 70%;
	background-repeat: no-repeat;
	background-size: 0 35%;
}

.card__container {
	display: flex;
	flex-flow: column nowrap;
	gap: 1.5em;
	justify-content: center;
	align-items: stretch;
}

.card {
	background: #fff;
}
.card__text {
	margin: 2.5em 1.5em;
	line-height: 1.6em;
	font-size: 1rem;
	letter-spacing: 0.5px;
	text-align: justify;
	font-family: var(--font-family-secondary);
}

.card__title {
	text-align: center;
	margin: 1em 0;
	font-weight: 800;
	font-size: 1.4em;
	color: #477053;
}

a > .card__title {
	font-weight: 700;
}

.card__image {
	height: 250px;
}

.card__image img {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

.img-filter-gradient {
	position: absolute;
	height: 100%;
	width: 100%;
	z-index: 2;
	background: linear-gradient(90deg, rgb(35 55 68) 0%, rgb(29 48 23 / 70%) 35%, rgb(175 186 189 / 0%) 100%);
}

.border-top-bot {
	border: 1px solid #ececec;
	border-left: 0;
	border-right: 0;
}

.object-position-top {
	object-position: top;
}
.row {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	align-content: center;
}

.section__content img.img-circle {
	flex: unset !important;
	height: 300px;
	width: 300px;
	max-height: unset;
	border-radius: 50%;
	margin: 0 auto;
}

.circle__vector__container {
	height: 100%;
	position: absolute;
	z-index: -1000;
}
.circle__vector {
	height: 300px;
	width: 300px;
	border-radius: 50%;
	top: -80px;
	left: 30px;
	background-color: #f6f6f6;
	position: absolute;
}

.no-py {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.no-pt {
	padding-top: 0 !important;
}

/*.row > .col {
	flex: 1;
}*/

ol,
ul {
	margin-left: 1rem;
	line-height: 1.6rem;
	font-family: var(--font-family-secondary);
}

ol li,
ul li {
	margin-bottom: 0.5rem;
}

p ul,
p ol {
	line-height: 1.4rem;
}

p ol li,
p ul li {
	justify-content: justify;
}

div.section__content.align-items-top {
	align-items: start;
}

div.section__content.align-items-bot {
	align-items: end;
}

.hero-video {
	width: 100%;
	width: 100%;
	position: relative;
	top: -135px;
}
.marginAuto {
	margin: 0 auto;
}

.alignCenter {
	text-align: center;
}

.loadingImage {
	max-width: 100%;
}

@media only screen and (min-width: 500px) {
	.section__header > p {
		max-width: 100%;
	}
}
@media only screen and (min-width: 768px) {
	.section__button {
		padding: 20px 50px;
		margin: 2rem auto;
		background-color: #41694e;
		border: 0;
		font-weight: 600;
		font-size: 14px;
		max-width: 300px;
	}
	.container {
		padding: 0 3rem;
	}

	.section__content img.img-circle {
		height: 400px;
		width: 400px;
	}

	.card__container {
		display: flex;
		flex-flow: row wrap;
		gap: 2.2rem;
	}

	.card {
		width: 400px;
	}

	h2 {
		font-size: 2.3rem;
	}
	p {
		font-size: 1rem;
	}
}

@media only screen and (min-width: 990px) {
	.section__container {
		flex-flow: row nowrap;
		gap: 2rem;
	}
	.section {
		padding: 6rem 0;
	}
}

/********************* ANIMATIONS ************************/
/* animation generator - animista.net*/
.scale-up-center {
	-webkit-animation: scale-up-center 0.5s ease-in-out both;
	animation: scale-up-center 0.5s ease-in-out both;
}

@-webkit-keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}
@keyframes scale-up-center {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.scale-up-left {
	-webkit-animation: scale-up-left 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
	animation: scale-up-left 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@-webkit-keyframes scale-up-left {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
	}
}
@keyframes scale-up-left {
	0% {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
	}
}

.scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
	animation: scale-up-ver-top 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

@-webkit-keyframes scale-up-ver-top {
	0% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
}
@keyframes scale-up-ver-top {
	0% {
		-webkit-transform: scaleY(0.4);
		transform: scaleY(0.4);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
	100% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 0%;
		transform-origin: 100% 0%;
	}
}

.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: fade-in-bottom 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.fade-out-bottom {
	-webkit-animation: fade-out-bottom 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: fade-out-bottom 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes fade-out-bottom {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}
}
@keyframes fade-out-bottom {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}
}

.fade-in-top {
	-webkit-animation: fade-in-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: fade-in-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes fade-in-top {
	0% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.fade-out-top {
	-webkit-animation: fade-out-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
	animation: fade-out-top 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes fade-out-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}
}
@keyframes fade-out-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-50px);
		transform: translateY(-50px);
		opacity: 0;
	}
}
