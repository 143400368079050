.breadcrumbs__container {
	background-color: #373737;
	color: #e9e9e9;
	letter-spacing: 0.05rem;
}
.breadcrumbs {
	display: flex;
	flex-flow: row wrap;
	padding: 0.8rem 2rem;
	max-width: 1440px;
	margin: auto;
	list-style: none;
	align-items: center;
	align-content: center;
	gap: 0.7rem;
	font-size: 0.8rem;
}

.breadcrumbs li span {
	font-weight: 700;
}

.breadcrumbs li > span {
	color: #6e9f77;
}

@media only screen and (min-width: 768px) {
	.breadcrumbs {
		font-size: 1rem;
	}
}
